import React, {Component} from 'react';
import {Button, Col, ListGroup, Row} from "react-bootstrap";

export default class FileList extends Component {
    render() {
        return (
            <ListGroup className="m-1">
                {this.props.files.map((file, index) =>
                    <ListGroup.Item action key={index} onClick={() => this.props.onSelectFile(file.name)}>
                        <Row>
                            <Col>{file.name}</Col>
                            <Col><Button variant="danger" onClick={() => this.props.onDeleteFile(file.name)}>Delete</Button></Col>
                        </Row>
                    </ListGroup.Item>)}
            </ListGroup>
        );
    }
}