import React, {Component} from "react";
import config from "./QuaggaConfig.json";
import Quagga from "quagga";
import "./style.css";

export default class BarCodeScanner extends Component {
    componentDidMount() {
        Quagga.init(config, err => {
            if (err) {
                alert(err);
                return;
            }
            Quagga.start();
        });
        Quagga.onDetected(result => this.props.onDetected(result.codeResult.code));
        Quagga.onProcessed(result => this.onProcessed(result));
    }

    onProcessed(result) {
        if (!result) {
            return;
        }
        const drawingCtx = Quagga.canvas.ctx.overlay;
        const drawingCanvas = Quagga.canvas.dom.overlay;

        if (result.boxes) {
            drawingCtx.clearRect(0, 0, Number(drawingCanvas.getAttribute("width")), Number(drawingCanvas.getAttribute("height")));
            result.boxes.filter(box => {
                return box !== result.box;
            }).forEach(box => {
                Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "#green", lineWidth: 2});
            });
        }

        if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
        }

        if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(result.line, {x: "x", y: "y"}, drawingCtx, {color: "red", lineWidth: 3});
        }
    }

    render() {
        return (
            <div id="interactive" className="viewport"/>
        )
    }

    componentWillUnmount() {
        Quagga.stop();
        Quagga.offDetected(this.props.onDetected);
    }
}