import React, {Component} from 'react';

export default class FileOutput extends Component{
    render() {
        if(!this.props.value && !this.props.fileType)
            return null;
        if(this.props.fileType === "text/plain"){
            return(
                <textarea className="w-100"
                          value={this.props.value}
                          readOnly={true}
                          rows={10}/>
            );
        }else{
            return(
                <img className="w-100" src={this.props.value} alt="Platzhalter"/>
            );
        }

    }
}