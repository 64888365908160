import React, {useState} from "react";
import {Button} from "react-bootstrap";
import BarcodeScanner from "./BarCodeScanner";

export default function BarCodeScannerComponent(props){
    const [camera, setCamera] = useState(false);
    const [result, setResult] = useState(null);

    const onDetected = result => {
        setResult(result);
    };

    return(
        <div>
            <h6>{result ? result : "Scanning..."}</h6>
            <Button variant={"primary"} onClick={() => setCamera(!camera)}>{camera ? "Stop" : "Start"}</Button>
            <div className="container">
                {camera && <BarcodeScanner onDetected={onDetected}/>}
            </div>
        </div>
    )
}