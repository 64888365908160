import React, {Component} from 'react';
import {Form} from "react-bootstrap";

export default class FileIOItem extends Component {
    render() {
        return (
            <Form.Control type="file"
                          multiple
                          className={"m-1"}
                          id={this.props.name}
                          onChange={e => this.props.onChange(e)}
            />
        );
    }
}