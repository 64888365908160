import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AppBar, Card, createMuiTheme, MuiThemeProvider} from '@material-ui/core'
import FormComponent from "./components/FormComponent";
import {Col, Row} from "react-bootstrap";
import BarCodeScannerComponent from "./components/BarCodeScannerComponent";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {text: ""};
    }

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#2581C4'
                },
                secondary: {
                    main: '#FABB00'
                },
            },
        });

        return (
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <AppBar position="static">
                        <h3>Scanner-Client</h3>
                    </AppBar>
                    <Row className={"justify-content-md-center"}>
                        <Col sm>
                            <Card style={{width: '80%', margin: '2em auto', padding: '1em'}}>
                                <h4>Barcode</h4>
                                <BarCodeScannerComponent/>
                            </Card>
                        </Col>
                        <Col sm>
                            <Card style={{width: '80%', margin: '2em auto', padding: '1em'}}>
                                <h4>FileSystem</h4>
                                <FormComponent/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default App;
