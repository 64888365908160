import React, {Component} from 'react';
import FileIOItem from "./FileIOItem";
import FileList from "./FileList";
import FileOutput from "./FileOutput";
import {Button} from "react-bootstrap";

export default class FormComponent extends Component {
    constructor(props) {
        super(props);
        window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
        if (window.requestFileSystem) {
            this.initFileSystem(1024 * 1024 * 100);
        }
        this.state = {};
    }

    initFileSystem(size){
        navigator.webkitPersistentStorage.requestQuota(size, neededSize => {
            window.requestFileSystem(window.PERSISTENT, neededSize, fs => {
                this.fileSystem = fs;
                this.fetchFiles();
            }, () => alert("Ihr Browser ist nicht kompatibel!!!"));
        })
    }

    writeFile(filename, content) {
        this.fileSystem.root.getFile(filename, {create: true, exclusive: true}, (fileEntry) => {
            fileEntry.createWriter(fileWriter => {

                let contentBlob = new Blob([content], {type: 'text/plain'});

                fileWriter.write(contentBlob);

                fileWriter.onwriteend = e => {
                    console.log("Erfolgreich");
                };
                fileWriter.onerror = e => {
                    console.log("Fehler!!");
                };
            })
        }, (e) => console.log(e))
    }

    deleteFile(fileName){
        this.fileSystem.root.getFile(fileName, {create: false}, fileEntry => {
            fileEntry.remove(() => {
                console.log("File removed!");
                this.fetchFiles();
            }, (e) => console.log(e));
        }, (e) => console.log(e));
    }

    fetchFiles(){
        const dirReader = this.fileSystem.root.createReader();
        let files = [];
        const fetchEntries = () => {
            dirReader.readEntries((results) => {
                if(results.length){
                    files = files.concat(results);
                    console.log(results);
                    fetchEntries();
                }else{
                    this.setState({files: files});
                }
            }, (e) => console.log(e));
        };
        fetchEntries();
    }

    selectFile(fileName){
        console.log("selectFile");
        this.fileSystem.root.getFile(fileName, {}, fileEntry => {
            fileEntry.file(file => {
                console.log(file);
                const reader = new FileReader();
                if(file.type === "text/plain")
                    reader.readAsText(file);
                else
                    reader.readAsDataURL(file);
                reader.onloadend = e => {
                    this.setState({selectedFile: e.target.result, fileType: file.type});
                };
                reader.onerror = e => {
                    console.log(e);
                };
            }, (e) => console.log(e));
        }, (e) => console.log(e));
    }

    render() {
        return (
            <div>
                <FileIOItem name={"Input"} onChange={this.onChangeFileIO.bind(this)}/>
                <FileList files={this.state.files ? this.state.files : []}
                          onDeleteFile={this.deleteFile.bind(this)}
                          onSelectFile={this.selectFile.bind(this)}
                />
                <Button className={"m-1"} variant={"primary"} onClick={() => this.writeFile("Test.txt", "TestText")}>Write something!</Button>
                <FileOutput value={this.state.selectedFile} fileType={this.state.fileType}/>
            </div>
        );
    }

    onChangeFileIO(e) {
        console.log("onChangeFileIO");
        let files = e.target.files;
        for(let file of files){
            this.fileSystem.root.getFile(file.name, {create: true, exclusive: true}, fileEntry => {
                fileEntry.createWriter(fileWriter => {
                    fileWriter.write(file);
                    fileWriter.onwriteend = e => {
                        this.fetchFiles();
                    };
                    fileWriter.onerror = e => {
                        alert("Error");
                    };
                });
            }, e => console.log(e));
        }
    }
}